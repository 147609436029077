import styled, { css } from "styled-components";
import mediaQueryBuilder from "helpers/mediaQueryBuilder";

export const NotOnMobile = styled.span`
  display: none;
  ${mediaQueryBuilder.widthMobile(css`
    display: inline-block;
  `)};
`;

export const OnlyOnMobile = styled.span`
  display: inline-block;
  ${mediaQueryBuilder.widthMobile(css`
    display: none;
  `)};
`;
