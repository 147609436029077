import React from "react";
import { graphql, Link } from "gatsby";

import { SharedLayout } from "layouts/SharedLayout";
import SEO from "components/seo";
import {
  Strong,
  P,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledA,
  Ul,
  Li,
} from "styles/Typography";
import { SeparatedSection, DesktopFloatingColumn, Row, Box, StyledSection } from "styles/Containers";
import { CarBadge } from "components/CarBadge";
import { BareLink } from "styles/Buttons";
import { NotOnMobile } from "styles/Responsive";
import "helpers/fontAwesome";
import details from "../images/technical_details.png";
import carcrash from "../images/car_crash.jpg";
import calculator from "../images/calculator.jpg";
import sampleRating from "../images/sample_rating.png";
import { ImageBlock } from "components/ImageBlock";
import { CarSearch } from "components/CarSearch";
import { carPath, safetyRating } from "helpers/carSelectors";

export const query = graphql`
  query CarsForSearch {
    allCarData(sort: { fields: CoalescedProbDeath }) {
      nodes {
        CarTypeTeoalida
        FormalModelName
        FormalMakeName
        ModelYear
        CoalescedProbDeath
        CoalescedRscRating
        VehicleSizeCategory
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const allCarData = data.allCarData.nodes;
  return (
    <SharedLayout>
      <SEO title="Home" />
      <StyledH1>Safest Cars</StyledH1>
      <StyledSection>
        <StyledH2>Wondering what are the safest cars?</StyledH2>
        <P>
          We've developed a holistic system that draws data from a variety of
          sources to more effectively evaluate car safety. Designed by
          economists and machine learning experts Ben Shiller and Bhoomija
          Ranjan,{" "}
          <Strong>
            our system uses artificial intelligence along with real-world
            accident data to produce more accurate and meaningful car safety
            ratings
          </Strong>
          .
        </P>
      </StyledSection>

      <SeparatedSection>
        <StyledH2>Find a car</StyledH2>
        <CarSearch carSearchData={allCarData} />
      </SeparatedSection>
      <StyledSection>
        <StyledH2>Our Safety Ratings</StyledH2>
        <Ul>
          <Li>
            <ImageBlock img={details}>
              <P>
                We start with measurements taken during safety tests by the
                NHTSA, and other vehicle characteristics.
              </P>
              <P>
                These measurements tell us about the durability of vehicles
                under pre-specified accident conditions.
              </P>
            </ImageBlock>
          </Li>
          <Li>
            <ImageBlock img={carcrash}>
              <P>
                We compare these measurements against statistics of fatality
                rates in actual car accidents to assess their importance to
                safety in real world conditions.
              </P>
            </ImageBlock>
          </Li>
          <Li>
            <ImageBlock img={calculator}>
              <P>
                We use advanced statistical and artificial intelligence
                techniques to address potential biasing factors to our model,
                such as the tendency of safer drivers to drive safer cars.
              </P>
            </ImageBlock>
          </Li>
          <Li>
            <ImageBlock noBorder img={sampleRating}>
              <P>
                We give ratings in terms of yearly probabilities of accident
                fatality under typical driving conditions. Not only is this
                inherently meaningful, but it makes it possible to compare
                vehicles of different classes on the same scale.
              </P>
            </ImageBlock>
          </Li>
        </Ul>
      </StyledSection>
      <StyledSection>
        <StyledH2>Our Advantage</StyledH2>
        <DesktopFloatingColumn>
          <Box>
            <br />
            <StyledH3 noMargin>
              <NotOnMobile>100&nbsp;</NotOnMobile>Safest
              <NotOnMobile>&nbsp;Vehicles</NotOnMobile> of 2020
            </StyledH3>
            <br />
            <ol>
              {allCarData
                .filter((c) => c.ModelYear === "2020")
                .sort((a,b) => safetyRating(a) < safetyRating(b) ? 1 : -1)
                .slice(0, 100)
                .map((c) => (
                  <li>
                    <Link to={carPath(c)}>
                      <BareLink>
                        {c.FormalMakeName} {c.FormalModelName}
                      </BareLink>
                    </Link>
                  </li>
                ))}
            </ol>
          </Box>
        </DesktopFloatingColumn>
        <P>
          The NHTSA and IIHS safety ratings are based on similar information,
          but their choice to use discrete ratings makes comparisons difficult.
          NHTSA safety ratings are on a five-star scale, but nearly all vehicles
          receive 4 or 5 stars. IIHS awards top safety picks and top safety
          picks plus, lumping vehicles into few safety categories. Many vehicles
          receive a top rating (including some which aren't very safe). In 2020,
          96 different vehicles were awarded a top safety pick or top safety
          pick plus award from the IIHS. It's not easy to determine which of
          these top scoring vehicles are the safest.
        </P>
        <P>
          Our mission is to improve safety ratings where the IIHS and NHTSA's
          crashworthiness ratings are weak. We use complex methods from academic
          research to relate vehicle features and crash test measurements to
          empirical death rates to predict how safe a vehicle is overall. We
          present fine-grained ratings that allow direct comparisons between
          vehicles. <Strong>We strive to provide superior ratings</Strong> to
          provide information you need{" "}
          <Strong>to keep your loved ones safe</Strong> when buying a new car.
        </P>
        <P>
          The National Highway Traffic Safety Administration (NHTSA) and the
          Insurance Institute for Highway Safety (IIHS) rely on staged crash
          tests. They will inform you how safe a car is{" "}
          <Strong>
            given that real-world crash conditions exactly match the
            conditions in the staged crash test.
          </Strong>{" "}
          For example, the NHTSA's frontal crash test smashes the entire front
          of the vehicle moving at 35 mph into another barrier at no angle. The
          IIHS has shown that vehicles that perform well on the NHTSA's test may
          not actually be safe if only 40% (or 25%) of the vehicle overlaps with
          the other vehicle in the crash. While the IIHS provides some
          additional information, neither the IIHS nor NHTSA's tests inform about
          how crashworthy the vehicle is at higher speeds or different crash
          angles.
        </P>
        <P>
          In fact, the IIHS found that automakers have tailored their safety
          features to perform well <Strong>only</Strong> when the test
          conditions exactly match their specifications. In 2012, they
          introduced the smaller overlap test, where 25% of the driver's side
          crashes into a fixed barrier. Vehicles performed very badly on this
          new test. A few years later automakers had redesigned their cars to
          perform well on these tests.
          <Strong>Why?</Strong> Because they want to sell more cars, and cars
          recognized as safe sell more. But the IIHS found a few years later
          that automakers had typically only reinforced the driver-side. When
          they ran exactly the same test on the passenger-side, the same
          vehicles that had improved on the driver side still performed poorly
          on the passenger-side.
          <sup>
            <a
              target="_blank"
              href="https://www.cnet.com/roadshow/news/iihs-to-consider-passenger-side-small-overlap-test-after-crossovers-fail-to-protect"
            >
              1
            </a>
          </sup>{" "}
          Try explaining that to you spouse/kids/parents/etc. seated on the
          passenger-side.
        </P>
        <P>
          Additionally, the tests run by the IIHS and NHTSA happen at
          predetermined speeds, all less than or equal to 40 mph. Automakers
          could design their vehicles to perform well on crashes at these
          speeds, for example by designing the safety cage not to collapse at
          that speed. But maybe the cabin would collapse at higher speeds (e.g.
          65 mph) crushing occupants. That would be fine as long as you (as well
          as everyone else on the road) never exceeds 40 mph.
        </P>
        <Row spaceAround style={{ maxWidth: "25em" }} center>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ak-qVkW888k?start=43"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
          <caption style={{ marginTop: "-1em" }}>
            If you drive at highway speeds, this could happen.
          </caption>
          <br />
        </Row>
        <P>
          Creating our safety ratings was no easy task. The fact that safer
          drivers typically choose safe-rated vehicles raises significant
          challenges when relating vehicle features to death rates. Are drivers
          of safer-rated vehicles (e.g. top safe picks / Volvos / minivans) less
          likely to die or be hurt because the car is keeping them safe, or
          because they have safe driving habits? If their driving habits explain
          these differences, then the car isn't inherently safer and would not
          help protect you and your loved ones. Our ratings solve this selection
          problem using a variety of methods, including{" "}
          <StyledA
            target="_blank"
            href="https://ideas.repec.org/p/brd/wpaper/132.html"
          >
            methods from academic research
          </StyledA>
          .
        </P>
        <P>
          Keep in mind that some safety features are optional or only available
          on select trim levels. We highly suggest you choose a vehicle with
          novel advanced safety features. Evidence to date suggests that front
          crash prevention / automatic emergency braking (which breaks for you
          when a collision is imminent) is the most important feature to have,
          for obvious reasons --- it can slow your speed down (say from 60 to
          40) reducing the severity of the collision. Forward collision warnings
          may help as well, but not as much (computers have faster reflexes than
          we do). We also recommend blind-spot monitoring, rear-view cameras,
          and rear automatic braking. If you live in an area with snow, all
          wheel drive can be an important safety feature. We do NOT recommend
          relying on self-driving or adaptive cruise control features. They are
          just not reliable enough to prevent a serious accident, at least not
          yet. Even the best systems from Tesla, Subaru, and Volvo have
          well-known weaknesses.
        </P>
        <P>
          Other safety technologies protect others or are too new to be verified
          in the field. But you may want to consider pedestrian detection,
          adaptive headlights which point towards where you are turning, driver
          monitoring systems (DMS) which alert distracted/sleeping drivers, rear
          automatic braking, powerful headlights / high-beams, simple
          infotainment systems, etc.
        </P>
        <P>
          <Strong>You may notice</Strong> that heavier and taller vehicles like
          mid-side SUVs perform well in accidents. It is well-known that heavier
          vehicles generally are safer in multiple car accidents. Our
          exploration revealed the counterintuitive finding that taller vehicles
          are safer. Most people worry that they are more likely to roll over,
          which is true. But rollover accidents are relatively uncommon these
          days (with electronic stability control). In other types of accidents
          SUVs perform better. If a taller SUV collides with a shorter sedan,
          then the much of the force happens low on the SUV's frame, below the
          passengers’ torsos. Hence, much of the damage is to the vehicle's
          frame, rather than the passenger cabin. SUV crash tests which use a
          fixed barrier (a wall or pole) are unable to capture the impact of
          height on safety. But our ratings do.
        </P>
      </StyledSection>
    </SharedLayout>
  );
};

export default IndexPage;
