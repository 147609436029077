import styled from "styled-components";

export const ImageBlockContainer = styled.div`
  display: grid;
  @media screen and (min-width: 600px) {
    grid-template-columns: 30% 70%;
    grid-gap: 1em;
  }
  align-items: center;
  margin-top: 3em;
`;

export const ImageBlockImage = styled.img`
  width: 12em;
  border: 1px solid grey;
  margin: 1em;
  @media screen and (max-width: 600px) {
    margin: auto;
    width: 90%;
  }
  ${p => p.noBorder && `
    border: 0;
 `}
`;

export const ImageBlockImageComponent = styled.div`
  width: 12em;
  border: 1px solid grey;
  margin: 1em;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 600px) {
    margin: auto;
  }
  ${p => p.noBorder && `
    border: 0;
 `}
`;
export const ImageBlockContent = styled.div`
  margin: 1em;
`;
