import React from "react";
import PropTypes from "prop-types";
import {
  ImageBlockContainer,
  ImageBlockImage,
  ImageBlockImageComponent,
  ImageBlockContent,
} from "./ImageBlock.styles";

export const ImageBlock = (props) => {
  return (
    <ImageBlockContainer>
      {props.img && ( <ImageBlockImage src={props.img} noBorder={props.noBorder} />
      )}
      {props.component && ( <ImageBlockImageComponent noBorder={props.noBorder}>{props.component()}</ImageBlockImageComponent>)}
      <ImageBlockContent>{props.children}</ImageBlockContent>
    </ImageBlockContainer>
  );
};
